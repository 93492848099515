<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1 pb-2">
            <v-form ref="form" @submit.prevent="buscar()">
              <v-row>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Rubro
                  <v-autocomplete
                    v-model="filtro.rubros"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="rubros"
                    :rules="[rules.required]"
                    validate-on-blur
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                    @blur="get_categorias()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="py-1">
                  Categoria
                  <v-autocomplete
                    v-model="filtro.categorias"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="categorias"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Tipo lista
                  <v-autocomplete
                    v-model="filtro.tipo_lista"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="tipos_lista.filter(t => t.inhabilitado != 1)"
                    :rules="[rules.required]"
                    validate-on-blur
                    hide-details
                    outlined
                    dense
                    @change="get_listas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="py-1">
                  Listas
                  <v-autocomplete
                    v-model="filtro.listas"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="listas"
                    :rules="[rules.required]"
                    validate-on-blur
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1">
                  Días
                  <v-text-field
                    v-model="filtro.dias"
                    type="number"
                    tabindex="1"
                    :rules="[rules.required]"
                    validate-on-blur
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Porc{{ $vuetify.breakpoint.smAndDown ? '.' : 'entaje' }} aumento
                  <v-text-field
                    v-model="filtro.porcentaje"
                    type="number"
                    tabindex="1"
                    suffix="%"
                    :rules="[rules.required]"
                    validate-on-blur
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Vigencia
                  <FechaPicker
                    v-model="filtro.vigencia"
                    :fecha_minima="moment(new Date()).format('DD/MM/YYYY')"
                    :hideDetails="true"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1 pr-0 mr-n8">
                  <v-checkbox
                    v-model="filtro.en_stock"
                    label="En stock"
                    class="pt-6 pt-md-3"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" sm="3" md="1" class="py-1 pl-xs-0 mr-8">
                  <v-checkbox
                    v-model="costos"
                    label="Aplica costos"
                    class="pt-sm-6 pt-md-1"
                    :true-value="1"
                    :false-value="null"
                    hide-details
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="8" md="6" class="d-flex pb-1 pt-3 pt-sm-1">
                  <div class="mr-2">
                    <div class="d-flex justify-end">
                      {{ filtro.incluir ? 'Incluir' : 'Excluir' }}
                    </div>
                    <div class="d-flex justify-end">
                      <v-switch
                        v-model="filtro.incluir"
                        class="my-0"
                        tabindex="1"
                        hide-details
                        dense
                      ></v-switch>
                    </div>
                  </div>
                  <div style="width: 100%;">
                    Marcas
                    <v-autocomplete
                      v-model="filtro.marcas"
                      item-text="nombre"
                      item-value="codigo"
                      tabindex="1"
                      :items="marcas"
                      deletable-chips
                      hide-details
                      small-chips
                      clearable
                      multiple
                      outlined
                      dense
                    ></v-autocomplete>
                  </div>
                </v-col>
                <BtnFiltro
                  clase="mt-md-0 mt-2 mb-n2"
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="precios"
        :item-class="itemRowBackground"
        :loading="load"
        :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
        :sort-by="['estado']"
        multi-sort
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="9" md="10" class="d-flex justify-start align-center">
              <DownloadDataTable
                :data="precios"
                :name="`Precios ${moment(new Date()).format('YYYYMMDD')}`"
                :headers="headers_excel"
              />
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <!-- espacio libre para el search -->
            </v-col>
          </v-row>
        </template>
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-icon
            :color="item.estado ? (item.estado == 1 ? '' : (item.estado == 2 ? 'warning' : 'success')) : 'orange'"
            :title="item.estado ? (item.estado == 1 ? 'Pendiente' : (item.estado == 2 ? item.error_desc : 'Se procesó con éxito')) : 'Línea duplicada'"
            small
          >
            {{ item.estado ? (item.estado == 1 ? 'far fa-clock' : (item.estado == 2 ? 'fas fa-exclamation-triangle' : 'fas fa-check')) : 'fas fa-exclamation-triangle' }}
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="error"
            title="Quitar línea"
            class="mr-3"
            :disabled="item.estado > 1"
            small
            @click="quitar(item); valdiar_repetidos()"
          >
            fas fa-times-circle
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <v-row class="d-flex justify-end pt-1 mb-4 mx-3">
      <v-btn
        v-if="errores > 0"
        color="primary"
        class="mt-2 ml-2"
        @click="depurar()"
      >
        <v-icon left>fas fa-filter</v-icon>
        Depurar exitosos
      </v-btn>
      <BtnConfirmar
        clase="mt-2 ml-2"
        :disabled="bloquear || guardado || precios.length == 0"
        @action="actualizar_precios()"
      />
    </v-row>
  </v-row>
</template>

<script>
/**
 * Lista de estados de los precios:
 * 
 *    0 : repetido
 *    1 : pendiente de subida
 *    2 : con error
 *    3 : ok
 */
import moment from 'moment'
import { format_money, order_list_by } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import DownloadDataTable from '../../components/util/DownloadDataTable'
import FechaPicker from '../../components/util/FechaPicker'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      moment: moment,
      panel: 0,
      errores: 0,
      load: false,
      bloquear: true,
      guardado: false,
      costos: null,
      rubros: [],
      categorias: [],
      tipos_lista: [],
      listas: [],
      precios: [],
      headers: [
        { text: 'Lista', value: 'lista' },
        { text: 'Artículo', value: 'articulo' },
        { text: 'Precio actual', value: 'precio_viejo', align: 'end', formatter: format_money },
        { text: 'Precio a modificar', value: 'precio', sortable: false, align: 'end', formatter: format_money },
        { text: 'Última vigencia', value: 'fecha' },
        { text: 'Vigencia a modificar', value: 'vigencia' },
        { text: 'Cant.', value: 'cantidad', align: 'center' },
        { text: 'Antigüedad', value: 'antiguedad' },
        { text: 'Estado', value: 'estado', align: 'center' },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ],
      headers_excel: {
        'ARTÍCULO CÓDIGO': 'articulo_codigo',
        'ARTÍCULO': 'articulo_nombre',
        'PRECIO ACTUAL': 'precio_viejo',
        'ÚLTIMA VIGENCIA': 'fecha',
        'LISTA': 'lista_nombre',
        'LISTA CÓDIGO': 'lista_codigo',
        'PRECIO A MODIFICAR': 'precio',
        'VIGENCIA A MODIFICAR': 'vigencia'
      },
      filtro: {
        rubros: null,
        categorias: null,
        tipo_lista: null,
        listas: [],
        marcas: [],
        incluir: false,
        dias: null,
        porcentaje: null,
        vigencia: moment(new Date()).add(1, 'days').format('DD/MM/YYYY'),
        en_stock: 1
      },
      rules: { required: value => !!value || 'Campo requerido' }
    }
  },
  async created () {
    this.$store.state.loading = true
    // obtiene los rubros habilitados
    await this.$store.dispatch('rubros/get_rubros', { inhabilitado: 0 })
      .then((res) => {
        this.rubros = res.data
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    order_list_by(this.rubros, 'nombre')
    // obtiene los tipos de lista
    await this.$store.dispatch('listas/get_tipos')
      .then((res) => {
        this.tipos_lista = res.data
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    // obtengo las marcas
    await this.$store.dispatch('marcas/get_marcas_habilitadas')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('marcas', ['marcas'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    DownloadDataTable,
    FechaPicker
  },
  watch: {
    filtro: {
      handler: function () {
        this.precios = []
      },
      deep: true
    }
  },
  methods: {
    async actualizar_precios () {
      this.$store.state.loading = true
      this.errores = 0

      const data = await this.$store.dispatch('precios/format_data', this.precios)

      await this.$store.dispatch('precios/insertar', {
        precios: data,
        costos: this.costos,
        porcentaje: this.filtro.porcentaje
      })
        .then(async (res) => {
          // actualzia el estado de los precios en la lista
          for (let index = 0; index < this.precios.length; index++) {
            let precio = this.precios[index]
            precio.estado = 3
          }
          // cambia la vigencia de los precios por la recien insertada solo si la vigencia nueva es igual a la fecha de hoy
          if (this.filtro.vigencia == moment(new Date()).format('DD/MM/YYYY')) {
            await this.$store.dispatch('precios/actualizar')
              .then(() => {
                this.$swal.fire({
                  icon: 'success',
                  title: res.message,
                  text: 'Se actualizaron ' + data.length + ' precios'
                })
              })
              .catch(error => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.message,
                  text: 'Se actualizaron ' + data.length + ' precios pero no se pudo quitar de vigencia algunos precios anteriores: ' + error.message
                })
              })
          } else {
            this.$swal.fire({
              icon: 'success',
              title: res.message,
              text: 'Se actualizaron ' + data.length + ' precios'
            })
          }
        })
        .catch(error => {
          const correctos = error.stack
          if (correctos) {
            this.errores = 1
            // cambia el estado a los registros correctos
            for (let index = 0; index < correctos; index++) {
              let precio = this.precios[index]
              precio.estado = 3
            }
            // cambia el estado a los erroneos
            for (let index = correctos; index < this.precios.length; index++) {
              let precio = this.precios[index]
              precio.error_desc = error.message
              precio.estado = 2
            }
            // muestra mensaje
            this.$swal.fire({
              icon: 'warning',
              title: 'Actualización de precios finalizada, revise el estado de los precios que no se pudieron cambiar',
              html: `Correctos: ${correctos} <br/> Con error: ${this.precios.length - correctos}`
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
              timeout: 4000
            })
          }
        })
      
      /*
      // cicla por cada precio en la lista
      for (let index = 0; index < this.precios.length; index++) {
        let precio = this.precios[index]
        // inserta el precio nuevo
        await this.$store.dispatch('precios/insertar', precio)
          .then(() => {
            precio.estado = 3
          })
          .catch(error => {
            precio.estado = 2
            precio.error_desc = error.message
            this.errores += 1
          })
      }

      // arma un mensaje personalizado en base a los errores
      let icono = 'success'
      let mensaje = `Actualización de precios finalizada correctamente <p style="font-size: 22px; margin-top: 12px">Se cambiaron ${this.precios.length} precios</p>`
      if (this.errores > 0) {
        icono = 'warning'
        mensaje = `Actualización de precios finalizada, revise el estado de los precios que no se pudieron cambiar
                  <p style="font-size: 22px; margin-top: 12px; margin-bottom: 0px">Correctos: ${this.precios.length - this.errores} <br/> Con error: ${this.errores}</p>`
      }
      this.$swal.fire({
        icon: icono,
        title: mensaje
      })

      // cambia la vigencia de los precios por la recien insertada
      await this.$store.dispatch('precios/actualizar')
        .then(() => {
          // todo ok, muestra el mensaje personalizado
          this.$swal.fire({
            icon: icono,
            title: mensaje
          })
        })
        .catch(async (error) => {
          // muestra el mensaje personalizado primero y luego muestra un snakbar de error
          await this.$swal.fire({
            icon: icono,
            title: mensaje
          })
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
            timeout: 5000
          })
        })
      */

      this.guardado = true
      this.$store.state.loading = false
    },
    async buscar () {
      if (this.$refs.form.validate()) {
        this.errores = 0
        this.precios = []
        this.$store.state.loading = true
        await this.$store.dispatch('precios/get_precios', this.filtro)
          .then((res) => {
            this.precios = res.data
            this.valdiar_repetidos()
            this.bloquear = false
            this.guardado = false
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Campos requeridos',
          color: 'error'
        })
      }
    },
    async get_listas () {
      this.$store.state.loading = true
      this.listas = []
      this.filtro.listas = []
      const tipo = this.filtro.tipo_lista
      if (tipo) {
        await this.$store.dispatch('listas/get_listas', {
          tipo: tipo,
          inhabilitada: 0
        })
          .then((res) => {
            res.data.forEach(lista => {
              this.listas.push({
                codigo: lista.codigo,
                nombre: lista.codigo + ' - ' + lista.nombre
              })
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        order_list_by(this.listas, 'nombre')
        // selecciona todas las listas
        this.listas.forEach(lista => {
          this.filtro.listas.push(lista.codigo)
        })
      }
      this.$store.state.loading = false
    },
    async get_categorias () {
      this.$store.state.loading = true
      this.categorias = []
      this.filtro.categorias = null
      const rubros = this.filtro.rubros
      if (rubros) {
        for (let index = 0; index < rubros.length; index++) {
          const rubro = rubros[index]
          await this.$store.dispatch('categorias/get_categorias', {
            rubro: rubro,
            inhabilitado: 0
          })
            .then((res) => {
              const categorias = res.data
              categorias.forEach(categoria => {
                this.categorias.push(categoria)
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        }
        order_list_by(this.categorias, 'nombre')
      }
      this.$store.state.loading = false
    },
    valdiar_repetidos () {
      // agrupa los precios por su pk
      let group_by_repetidos = this.precios.reduce(function (r, a) {
        r[a.pk] = r[a.pk] || []
        r[a.pk].push(a)
        return r
      }, Object.create(null))

      // ciclo for por cada grupo de repetidos
      for (const key in group_by_repetidos) {
        let group = group_by_repetidos[key]
        // solo si el grupo tiene mas de uno significa que tiene repetidos
        if (group.length > 1) {
          // setea el estado de cada item repetido en 0
          for (const index in group) {
            let item = group[index]
            item.estado = 0
          }
        } else {
          group[0].estado = 1
        }
      }

      // solo si no existe ninguna row duplicada habilita el boton de guardar
      let duplicados = this.precios.find(i => i.estado == 0)
      if (!duplicados) {
        this.bloquear = false
      }
      // si quito la ultima row deshabilita el boton de guardar
      if (this.precios.length == 0) {
        this.bloquear = true
      }
    },
    depurar () {
      for (let index = this.precios.length - 1; index >= 0; index--) {
        const item = this.precios[index]
        if (item.estado == 3) {
          this.quitar(item)
        }
      }
      this.valdiar_repetidos()
      this.guardado = false
      this.errores = 0
    },
    quitar (item) {
      const index = this.precios.indexOf(item)
      this.precios.splice(index, 1)
    },
    itemRowBackground (item) {
      return !item.estado ? 'background-color: rowerr' : ''
    },
    limpiar () {
      this.$refs.form.resetValidation()
      this.categorias = []
      this.listas = []
      this.filtro = {
        rubros: null,
        categorias: null,
        tipo_lista: null,
        listas: [],
        marcas: [],
        incluir: false,
        dias: null,
        porcentaje: null,
        vigencia: moment(new Date()).add(1, 'days').format('DD/MM/YYYY'),
        en_stock: 1
      }
    }
  }
}
</script>